import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers as faUsersLight } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faUserChart as faUserChartLight } from '@fortawesome/pro-light-svg-icons/faUserChart';
import { faBuilding as faBuildingLight } from '@fortawesome/pro-light-svg-icons/faBuilding';
import { faStars as faStarsLight } from '@fortawesome/pro-light-svg-icons/faStars';
import { faFiles as faFilesLight } from '@fortawesome/pro-light-svg-icons/faFiles';
import { faUserCrown as faUserCrownLight } from '@fortawesome/pro-light-svg-icons/faUserCrown';
import { faCode as faCodeLight } from '@fortawesome/pro-light-svg-icons/faCode';
import { faBoxesAlt as faBoxesAltLight } from '@fortawesome/pro-light-svg-icons/faBoxesAlt';
import { faMeetup as faMeetupBrands } from '@fortawesome/free-brands-svg-icons/faMeetup';
import { faEdit as faEditLight } from '@fortawesome/pro-light-svg-icons/faEdit';
import { faInfo as faInfoLight } from '@fortawesome/pro-light-svg-icons/faInfo';
import { faPlayCircle as faPlayCircleLight } from '@fortawesome/pro-light-svg-icons/faPlayCircle';
import { faHandHoldingUsd as faHandHoldingUsdLight } from '@fortawesome/pro-light-svg-icons/faHandHoldingUsd';
import { faFacebookF as faFacebookFBrands } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faXTwitter as faXTwitterBrands } from '@fortawesome/free-brands-svg-icons/faXTwitter';
import { faUser as faUserLight } from '@fortawesome/pro-light-svg-icons/faUser';
import { faCalendarEdit as faCalendarEditLight } from '@fortawesome/pro-light-svg-icons/faCalendarEdit';
import { faBell as faBellLight } from '@fortawesome/pro-light-svg-icons/faBell';
import { faMoneyCheckPen as faMoneyCheckPenLight } from '@fortawesome/pro-light-svg-icons/faMoneyCheckPen';
import { faListUl as faListUlLight } from '@fortawesome/pro-light-svg-icons/faListUl';
import { faBookmark as faBookmarkLight } from '@fortawesome/pro-light-svg-icons/faBookmark';
import { faFolderOpen as faFolderOpenLight } from '@fortawesome/pro-light-svg-icons/faFolderOpen';
import { faNewspaper as faNewspaperLight } from '@fortawesome/pro-light-svg-icons/faNewspaper';
import { faFileChartPie as faFileChartPieLight } from '@fortawesome/pro-light-svg-icons/faFileChartPie';
import { faDatabase as faDatabaseLight } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { faChartPie as faChartPieLight } from '@fortawesome/pro-light-svg-icons/faChartPie';

import esgLogo from '../../assets/leftbar/esg.svg';
import esgActiveLogo from '../../assets/leftbar/esg-hover.svg';
import investorsRelationLogo from '../../assets/leftbar/ir-logo.svg';
import investorsRelationActiveLogo from '../../assets/leftbar/ir-hover.svg';
import insiderTransactionsLogo from '../../assets/leftbar/insider-transactions-logo.svg';
import insiderTransactionsActiveLogo from '../../assets/leftbar/insider-transactions-hover.svg';
import locationsLogo from '../../assets/leftbar/locations-copy.svg';
import locationsActiveLogo from '../../assets/leftbar/locations-hover.svg';
import sentimentAnalysisLogo from '../../assets/leftbar/sentiment-analysis.svg';
import sentimentAnalysisActiveLogo from '../../assets/leftbar/sentiment-analysis-hover.svg';
import sectorsLogo from '../../assets/leftbar/sectors-copy.svg';
import sectorsActiveLogo from '../../assets/leftbar/sectors-hover.svg';
import topicsAndAssetClassesLogo from '../../assets/leftbar/topics-asset-classes.svg';
import topicsAndAssetClassesActiveLogo from '../../assets/leftbar/topics-asset-classes_d.svg';
import premiumPublicationLogo from '../../assets/leftbar/premiumPublicationIcon.svg';
import premiumPublicationActiveLogo from '../../assets/leftbar/premiumPublicationIconActive.svg';
import allFeaturesAndContent from '../../assets/leftbar/all_features_and_content.svg';

import { INSIDER_TRANSACTIONS } from '../directory/topic_classes/tabPaths';
import { UK_PRIVATE_COMPANY_INSIGHTS_PATH } from '../ukPrivateCompanyInsights';

export const productsTabs = [
  {
    icon: <FontAwesomeIcon icon={faUsersLight} />,
    name: 'Consumer Products',
    href: '',
    id: 23,
    new: false,
    tabType: 'consumerProductsTab',
    tabOpened: false,
    menuCategory: 'consumerProducts',
    subTabs: [
      {
        name: 'All Products',
        href: '/products',
        id: 26,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'CityFALCON.ai',
        href: '/products/webapp',
        id: 27,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'IOS / Android Apps',
        href: 'https://www.cityfalcon.ai/products/mobileapp',
        id: 28,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'CityFALCON Voice Assistant',
        href: '/products/voiceassistant',
        id: 29,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'CityFALCON Chat Bot',
        href: '',
        id: 30,
        new: false,
        comingSoon: true,
        disabled: true,
        productsTabs: true,
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faUserChartLight} />,
    name: 'Consumer Audiences',
    href: '',
    id: 46,
    new: false,
    tabType: 'consumerAudiencesTab',
    tabOpened: false,
    menuCategory: 'consumerAudiences',
    subTabs: [
      {
        name: 'Retail Investors',
        href: '/segments/retail-investors',
        id: 47,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Retail Traders',
        href: '/segments/retail-traders',
        id: 48,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Daily Business News Reader',
        href: '/segments/daily-business-readers',
        id: 49,
        new: false,
        productsTabs: true,
        isReload: true,
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faBuildingLight} />,
    name: 'Enterprise Solutions',
    href: '',
    id: 24,
    new: false,
    tabType: 'enterpriseSolutionsTab',
    tabOpened: false,
    menuCategory: 'enterpriseProducts',
    subTabs: [
      {
        name: 'All Solutions',
        href: '/enterprise-products',
        id: 31,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Financial News API',
        href: '/products/api/financial-news',
        id: 32,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Financial Entity and Events Extraction with NLP',
        href: '/products/api/natural-language-processing',
        id: 33,
        new: false,
        productsTabs: true,
      },
      {
        name: 'Extract and Structure Insights from Your Own Content',
        href: '/products/extract-and-structure-insights',
        id: 50,
        new: false,
        productsTabs: true,
        isReload: true,
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faUserChartLight} />,
    name: 'Enterprise Audiences',
    href: '',
    id: 51,
    new: false,
    tabType: 'enterpriseAudiencesTab',
    tabOpened: false,
    menuCategory: 'enterpriseAudiences',
    subTabs: [
      {
        name: 'Brokers',
        href: '/segments/brokers',
        id: 52,
        new: false,
        productsTabs: true,
      },
      {
        name: 'Students, Professors, Academia',
        href: '/segments/academia',
        id: 53,
        new: false,
        productsTabs: true,
      },
      {
        name: 'Wealth Managers, Financial Advisors',
        href: '/segments/wealth-managers-advisors',
        id: 54,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'P2P Crowdfunding, VC, PE',
        href: '/segments/private-equity',
        id: 55,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Institutional Investors, Treasury',
        href: '/segments/institutional',
        id: 74,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Consultancy, Legal, Accounting',
        href: '/segments/consultancy-legal-accounting',
        id: 63,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Central Banks, Regulatory Agencies',
        href: '/segments/central-banks-regulatory',
        id: 75,
        productsTabs: true,
        isReload: true,
      },
    ],
  },
  {
    icon: <img width={17} height={16} src={allFeaturesAndContent} alt="All Features and Content" />,
    name: 'All Features and Content',
    href: 'https://www.cityfalcon.ai/features',
    id: 73,
    new: false,
    tabType: 'allFeaturesAndContentTab',
    tabOpened: false,
    menuCategory: 'allFeaturesAndContent',
    subTabs: [],
  },
  {
    icon: <FontAwesomeIcon icon={faStarsLight} />,
    name: 'Features',
    href: '',
    id: 72,
    new: false,
    tabType: 'keyFeaturesTab',
    tabOpened: false,
    menuCategory: 'features',
    subTabs: [
      {
        name: 'CityFALCON Score',
        href: '/features/cf-score',
        id: 67,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Watchlists',
        href: '/features/watchlists',
        id: 62,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Similar Stories Grouping',
        href: '/features/similarity',
        id: 66,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'News on Charts',
        href: '/features/news-on-charts',
        id: 65,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Key Headlines',
        href: '/features/summarisation',
        id: 59,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Sentiment',
        href: '/features/sentiment',
        id: 58,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Content Translation',
        href: '/features/translation',
        id: 68,
        new: false,
        productsTabs: true,
        isReload: true,
      },
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faFilesLight} />,
    name: 'Content',
    href: '',
    id: 71,
    new: false,
    tabType: 'contentTab',
    tabOpened: false,
    menuCategory: 'content',
    subTabs: [
      {
        name: 'News',
        href: 'https://www.cityfalcon.ai/learn-more',
        id: 70,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Premium Publications',
        href: 'https://www.cityfalcon.ai/products/premium-publications',
        id: 68,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Insider Transactions',
        href: 'https://www.cityfalcon.ai/features/insider-transactions',
        id: 71,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Official Company Filings',
        href: '/features/filings',
        id: 61,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Investor Relations',
        href: '/features/investor-relations',
        id: 60,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'ESG Content',
        href: '/features/esg',
        id: 64,
        new: false,
        productsTabs: true,
        isReload: true,
      },
      {
        name: 'Languages',
        href: '/features/language-coverage',
        id: 57,
        new: false,
        productsTabs: true,
        isReload: true,
      },
    ],
  },
];

export const pricingTabs = [
  {
    icon: <FontAwesomeIcon icon={faUserCrownLight} />,
    name: 'Subscription Plans',
    href: '/pricing',
    id: 35,
    new: false,
    menuCategory: 'pricing',
  },
  {
    icon: <FontAwesomeIcon icon={faCodeLight} />,
    name: 'Real-time API Plans',
    href: '/products/api/financial-news/pricing-api/commercial',
    id: 36,
    new: false,
    menuCategory: 'pricing',
  },
  {
    icon: <FontAwesomeIcon icon={faBoxesAltLight} />,
    name: 'Historical Data Sets',
    href: '/products/api/financial-news/pricing-historical',
    id: 37,
    new: false,
    menuCategory: 'pricing',
  },
];

export const moreTabs = [
  {
    icon: <FontAwesomeIcon icon={faMeetupBrands} />,
    name: 'Meetups',
    href: '/meetup',
    id: 20,
    new: false,
    menuCategory: 'more',
  },
  {
    icon: <FontAwesomeIcon icon={faEditLight} />,
    name: 'Blog',
    href: 'https://www.cityfalcon.ai/blog',
    id: 21,
    new: false,
    menuCategory: 'more',
    isReload: true,
  },
  {
    icon: <FontAwesomeIcon icon={faInfoLight} />,
    name: 'Help Centre',
    href: 'https://help.cityfalcon.com/hc/en-us',
    id: 22,
    new: false,
    menuCategory: 'more',
    isReload: true,
  },
  {
    icon: <FontAwesomeIcon icon={faPlayCircleLight} />,
    name: 'Video Tutorials',
    href: '/help-videos',
    id: 46,
    new: false,
    menuCategory: 'more',
  },
  {
    icon: <FontAwesomeIcon icon={faHandHoldingUsdLight} />,
    name: 'Investment Interest',
    href: '/invest',
    id: 47,
    new: false,
    menuCategory: 'more',
    isReload: true,
  },
];

export const socialMediaTabs = [
  {
    icon: <FontAwesomeIcon icon={faFacebookFBrands} />,
    name: 'Like on Facebook',
    href: 'https://www.facebook.com/cityfalconfollow',
    class: 'facebook',
    id: 9,
    new: false,
    isReload: true,
  },
  {
    icon: <FontAwesomeIcon icon={faXTwitterBrands} />,
    name: 'Follow on X',
    href: 'https://twitter.com/intent/user?screen_name=CityFalcon',
    class: 'twitter',
    id: 10,
    new: false,
    isReload: true,
  },
];

export const preferencesTabs = [
  {
    icon: <FontAwesomeIcon icon={faUserLight} />,
    name: 'Account Info',
    href: '/preferences/account',
    id: 11,
    new: false,
    menuCategory: 'preferences',
  },
  {
    icon: <FontAwesomeIcon icon={faCalendarEditLight} />,
    name: 'Subscription',
    href: '/preferences/subscription',
    id: 12,
    new: false,
    menuCategory: 'preferences',
  },
  {
    icon: <FontAwesomeIcon icon={faBellLight} />,
    name: 'Notifications',
    href: '/preferences/notifications',
    id: 13,
    new: false,
    menuCategory: 'preferences',
  },
  {
    icon: <FontAwesomeIcon icon={faMoneyCheckPenLight} />,
    name: 'Manage Watchlists',
    href: '/watchlists/manage',
    id: 14,
    new: true,
    matchFixURL: true,
    menuCategory: 'preferences',
  },
];

export const personalisedContentTabs = [
  {
    icon: <FontAwesomeIcon icon={faListUlLight} />,
    name: 'Watchlists',
    href: '/watchlists',
    id: 1,
    new: false,
    menuCategory: 'personalised',
  },
  {
    icon: <FontAwesomeIcon icon={faBookmarkLight} />,
    name: 'Bookmarks',
    href: '/bookmarks',
    id: 2,
    new: false,
    menuCategory: 'personalised',
  },
];

export const allContentTabs = [
  {
    icon: <FontAwesomeIcon icon={faFolderOpenLight} />,
    name: 'Topics Directory',
    href: '/news/directory',
    otherHref: [
      '/news/summary',
      '/news/stories',
    ],
    id: 3,
    new: false,
    menuCategory: 'all',
  },
  {
    icon: <FontAwesomeIcon icon={faNewspaperLight} />,
    name: 'News and Research',
    href: '/news',
    menuCategory: 'all',
    id: 4,
    new: false,
    matchFixURL: true,
    otherHref: [
      '/news/all',
      '/news/very_positive',
      '/news/positive',
      '/news/negative',
      '/news/very_negative',
    ],
  },
  {
    icon: <img width={20} height={13} src={premiumPublicationLogo} alt="Topics and Asset Classes" />,
    iconActive: <img width={20} height={13} src={premiumPublicationActiveLogo} alt="Topics and Asset Classes" />,
    name: 'Premium Publications',
    href: '/news/directory/premium-publications',
    menuCategory: 'all',
    id: 62,
    new: false,
    matchFixURL: true,
  },
  {
    icon: <img width={20} height={13} src={topicsAndAssetClassesLogo} alt="Topics and Asset Classes" />,
    iconActive: <img width={20} height={13} src={topicsAndAssetClassesActiveLogo} alt="Topics and Asset Classes" />,
    name: 'Topics & Asset Classes',
    href: '/news/directory/topic-class',
    id: 38,
    new: false,
    menuCategory: 'all',
    matchFixURL: true,
    otherHref: [
      '/news/directory/',
    ],
  },
  {
    icon: <img width={20} height={30} src={sectorsLogo} alt="Sectors" />,
    iconActive: <img width={20} height={30} src={sectorsActiveLogo} alt="Sectors" />,
    name: 'Sectors',
    href: '/news/directory/dcsc-sectors',
    menuCategory: 'all',
    id: 39,
    new: false,
    matchFixURL: true,
  },
  {
    icon: <img width={20} height={25} src={locationsLogo} alt="Locations" />,
    iconActive: <img width={20} height={25} src={locationsActiveLogo} alt="Locations" />,
    name: 'Locations',
    href: '/news/directory/locations',
    menuCategory: 'all',
    id: 40,
    new: false,
    matchFixURL: true,
  },
  {
    icon: <img width={20} height={20} src={sentimentAnalysisLogo} alt="Sentiment Analysis" />,
    iconActive: <img width={20} height={20} src={sentimentAnalysisActiveLogo} alt="Sentiment Analysis" />,
    name: 'Sentiment Analysis',
    href: '/news/sentiment',
    menuCategory: 'all',
    id: 46,
    new: true,
    matchFixURL: true,
    otherHref: [
      '/directory/sentiment/locations',
    ],
  },
  {
    icon: <FontAwesomeIcon icon={faFileChartPieLight} />,
    name: 'Company Filings',
    href: '/directory/filings',
    menuCategory: 'all',
    id: 41,
    new: false,
    matchFixURL: true,
  },
  {
    icon: <img width={20} height={13} src={investorsRelationLogo} alt="Investor Relations" />,
    iconActive: <img width={20} height={13} src={investorsRelationActiveLogo} alt="Investor Relations" />,
    name: 'Investor Relations',
    href: '/directory/investor-relations',
    menuCategory: 'all',
    matchFixURL: true,
    id: 42,
    new: false,
  },
  {
    icon: <img width={14} height={18} src={insiderTransactionsLogo} alt="Insider Transactions" />,
    iconActive: <img width={14} height={18} src={insiderTransactionsActiveLogo} alt="Insider Transactions" />,
    name: 'Insider Transactions',
    href: `/directory/${INSIDER_TRANSACTIONS}`,
    menuCategory: 'all',
    matchFixURL: true,
    id: 47,
    new: true,
  },
  {
    icon: <img width={20} height={13} src={esgLogo} alt="ESG" />,
    iconActive: <img width={20} height={13} src={esgActiveLogo} alt="ESG" />,
    name: 'ESG',
    href: '/directory/esg',
    menuCategory: 'all',
    matchFixURL: true,
    id: 43,
    new: false,
  },
  {
    icon: <FontAwesomeIcon icon={faDatabaseLight} />,
    name: 'Alternative Data',
    href: '/directory/alternative-data',
    menuCategory: 'all',
    matchFixURL: true,
    id: 44,
    new: false,
  },
  {
    icon: <FontAwesomeIcon icon={faChartPieLight} />,
    name: 'Private Company Insights',
    href: UK_PRIVATE_COMPANY_INSIGHTS_PATH,
    menuCategory: 'all',
    id: 45,
    new: true,
    class: 'uk_insights',
    matchFixURL: true,
  },
];
