import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { isMobile, isMobileOnly } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import autoBind from 'react-autobind';
import { Link } from 'react-router-dom';
import loadable from '@utils/loadable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown as faChevronDownLight } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronRight as faChevronRightLight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faUserChart as faUserChartLight } from '@fortawesome/pro-light-svg-icons/faUserChart';
import { faFileAlt as faFileAltLight } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faSearch as faSearchLight } from '@fortawesome/pro-light-svg-icons/faSearch';
import { faCog as faCogLight } from '@fortawesome/pro-light-svg-icons/faCog';
import { faBoxCheck as faBoxCheckLight } from '@fortawesome/pro-light-svg-icons/faBoxCheck';
import { faDonate as faDonateLight } from '@fortawesome/pro-light-svg-icons/faDonate';
import { faWindowRestore as faWindowRestoreLight } from '@fortawesome/pro-light-svg-icons/faWindowRestore';

import cityFalconIcon from '../../assets/chatbot/СFChatbot.svg';

import * as authentication from '../../actions/authentication.actions';

import withComponentName from '../../decorators/withComponentName';
import { SILVER, GOLD } from '../../data/permissions';

import Styles from './styles.module.scss';

import {
  preferencesTabs,
  socialMediaTabs,
  moreTabs,
  productsTabs,
  personalisedContentTabs,
  allContentTabs,
  pricingTabs,
} from '../../data/leftBar';
import { DESKTOP, TABLET, MOBILE } from '../../data/screenSizes';

import { getBrowserScrollTop, stringToBool } from '../../helpers/commonHelpers';
import StorageSvc from '../../services/StorageSvc';

const ToggleBtn = loadable(() => import('./ToggleBtn'));
const Item = loadable(() => import('./Item'));

const cx = classNames.bind(Styles);

/**
* Component must get as param 'href' (path), when he must be active
*/
export class LeftBar extends Component {
  constructor(props) {
    super(props);
    const { lightLeftBar, isDetailsPage, isChatbotPage } = props;

    this.onScrollEventAdded = false;

    this.state = {
      leftBarOpened: false,
      personalisedSectionOpened: false,
      allSectionOpened: false,
      pricingSectionOpened: false,
      preferencesSectionOpened: false,
      moreSectionOpened: false,
      productsSectionOpened: false,
      consumerProductsTab: false,
      enterpriseSolutionsTab: false,
      pricingTab: false,
      homepageLeftbarOpened: false,
      initialCategoryExpanded: false,
      consumerAudiencesTab: false,
      enterpriseAudiencesTab: false,
      keyFeaturesTab: false,
      contentTab: false,
      currentMenuCategory: '',
      mode: DESKTOP,
      styles: {
        height: 'calc(100vh - 50px)',
        position: 'fixed',
        top: lightLeftBar || isDetailsPage || isChatbotPage ? '80px' : '50px',
      },
      isHeightSmall: false,
    };
    autoBind(this);

    this.leftBarRef = createRef();
    this.toggleBtnRef = createRef();
  }

  componentDidMount() {
    document.addEventListener('click', this.closeLeftBar);
    this.onResize();
  }

  componentDidUpdate(prevProps) {
    const {
      promobarHeight,
      lightLeftBar,
      isDetailsPage,
      href,
      width,
    } = this.props;

    if (
      prevProps.promobarHeight !== promobarHeight
      || lightLeftBar !== prevProps.lightLeftBar
      || isDetailsPage !== prevProps.isDetailsPage
      || href !== prevProps.href
    ) {
      this.onScroll();
    }

    if (width !== prevProps.width) {
      this.onResize();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeLeftBar);
    if (this.onScrollEventAdded) window.removeEventListener('scroll', this.onScroll);
  }

  onResize() {
    const {
      lightLeftBar, isDetailsPage, width, isChatbotPage,
    } = this.props;

    let mode;

    switch (true) {
      case width >= 1024:
        mode = DESKTOP;
        break;
      case width >= 768:
        mode = TABLET;
        break;
      default:
        mode = MOBILE;
        break;
    }

    if (width >= 1024 && !this.onScrollEventAdded) {
      window.addEventListener('scroll', this.onScroll, { passive: true });
      this.onScrollEventAdded = true;
    } else if (width >= 768 && width < 1024 && this.onScrollEventAdded) {
      window.removeEventListener('scroll', this.onScroll);
      this.onScrollEventAdded = false;
      this.setState({
        styles: {
          height: 'calc(100vh - 50px)',
          position: 'absolute',
          top: lightLeftBar || isDetailsPage || isChatbotPage ? '80px' : '55px',
        },
      });
    } else if (width < 768 && !this.onScrollEventAdded) {
      window.addEventListener('scroll', this.onScroll, { passive: true });
      this.onScrollEventAdded = true;
      this.setState({
        styles: {
          height: 'calc(100vh - 50px)',
          position: 'absolute',
          top: '50px',
        },
      });
    }

    this.setState({
      mode,
    });
    this.onScroll();
  }

  onScroll() {
    const { width } = this.props;
    const {
      promobarHeight, lightLeftBar, isDetailsPage, href, isChatbotPage,
    } = this.props;

    const scrollTop = getBrowserScrollTop();
    const smallHeaderHeight = (scrollTop <= 50 && width >= 768) || ((!StorageSvc.getItem('mobilePopupSeen') || !stringToBool(StorageSvc.getItem('promoID'))) && width < 768) ? 50 : 0;
    const headerHeight = ((lightLeftBar || isDetailsPage) && width >= 768) || isChatbotPage ? 80 : smallHeaderHeight;
    const top = promobarHeight + headerHeight + (width < 1025 && headerHeight !== 80 ? 5 : 0);
    const isMobileFixed = scrollTop > promobarHeight;

    const styles = {};
    if (width < 767) {
      styles.height = 'calc(100vh - 55px)';
      if (promobarHeight > 0) styles.height = `calc(100vh - ${promobarHeight + 55}px)`;
      styles.position = isMobileFixed ? 'fixed' : 'absolute';
      if (href === '/') {
        styles.top = (isMobileFixed && (!StorageSvc.getItem('mobilePopupSeen')) && isMobileFixed) || (stringToBool(StorageSvc.getItem('mobilePopupSeen')) && StorageSvc.getItem('promoID')) ? '80px' : `${top + 24}px`;
        if (styles.top === '130px' && isMobileFixed) {
          styles.top = '80px';
        }
      } else {
        styles.top = (isMobileFixed && (!StorageSvc.getItem('mobilePopupSeen') && !StorageSvc.getItem('promoID')) && isMobileFixed) || stringToBool(StorageSvc.getItem('mobilePopupSeen') && StorageSvc.getItem('promoID')) ? '50px' : `${top}px`;
      }

      if (top === 0
        || (isMobileFixed && !lightLeftBar && !isChatbotPage)
        || (stringToBool(StorageSvc.getItem('mobilePopupSeen'))
          && !lightLeftBar
          && StorageSvc.getItem('promoID')
          && !isChatbotPage
        )) {
        styles.top = '55px';
      }
      if (isChatbotPage) {
        styles.height = 'calc(100vh - 55px)';
        styles.top = (isMobileFixed && !StorageSvc.getItem('mobilePopupSeen'))
          || (stringToBool(StorageSvc.getItem('mobilePopupSeen'))
            && StorageSvc.getItem('promoID'))
          || (isMobileFixed && StorageSvc.getItem('mobilePopupSeen'))
          ? '80px'
          : `${top}px`;
        if (styles.top === '130px' && isMobileFixed) {
          styles.top = '80px';
        }
      }
    } else {
      let topMargin = 50 - (scrollTop <= 50 ? scrollTop : 50);
      const notificationsMenu = document.getElementById('preferences-menu');

      if (notificationsMenu && (width <= 1023 && width >= 768)) topMargin += 50;

      let height = topMargin + promobarHeight;
      // fixed height leftBar on size two bottom links on small heightScreen
      if (window.innerHeight < 870) height += 80;

      styles.height = `${window.innerHeight - height}px`;
      styles.position = 'fixed';
      styles.top = `${top}px`;
    }

    this.setState({
      styles,
      isMobileFixed,
    });
  }

  closeLeftBar(e) {
    if (
      this.leftBarRef.current?.contains(e?.target)
      || this.toggleBtnRef.current?.contains(e?.target)
    ) return;

    const { leftBarOpened } = this.state;

    if (leftBarOpened) {
      this.setState({
        leftBarOpened: false,
        consumerProductsTab: false,
        enterpriseSolutionsTab: false,
        pricingTab: false,
        consumerAudiencesTab: false,
        enterpriseAudiencesTab: false,
        keyFeaturesTab: false,
        contentTab: false,
      }, () => {
        this.toggleForHomePage(true);
      });
    }
  }

  toggleLeftBar() {
    this.setState(({ leftBarOpened }) => ({
      leftBarOpened: !leftBarOpened,
      consumerProductsTab: false,
      enterpriseSolutionsTab: false,
      pricingTab: false,
      consumerAudiencesTab: false,
      enterpriseAudiencesTab: false,
      keyFeaturesTab: false,
      contentTab: false,
    }), () => {
      const { leftBarOpened } = this.state;
      const { href } = this.props;
      if (leftBarOpened && href !== '/') {
        this.onScroll();
      }
      this.toggleForHomePage(true);
    });
    const { closeNavMenu } = this.props;
    closeNavMenu();
  }

  expandCollapsSubMenu(e, isCollapsed) {
    if (!e?.target) return;

    const element = e.target;
    let currentElement;

    // Find main element
    if (element.tagName === 'a') {
      currentElement = element;
    } else {
      currentElement = element.closest('a');
    }
    if (!currentElement) {
      return;
    }

    const childUIElement = currentElement.parentNode.getElementsByTagName('ul')[0];
    const sectionHeight = childUIElement.scrollHeight;

    if (isCollapsed) {
      childUIElement.style.height = `${sectionHeight}px`;

      // temporarily disable all css transitions
      const elementTransition = childUIElement.style.transition;
      childUIElement.style.transition = '';
      requestAnimationFrame(() => {
        childUIElement.style.transition = elementTransition;

        // on the next frame (as soon as the previous style change has taken effect),
        // have the element transition to height: 0
        requestAnimationFrame(() => {
          childUIElement.style.height = '0px';
        });
      });
    } else {
      // have the element transition to the height of its inner content
      childUIElement.style.height = `${sectionHeight}px`;

      // when the next css transition finishes (which should be the one we just triggered)
      childUIElement.addEventListener('transitionend', () => {
        // remove "height" from the element's inline styles, so it can return to its initial value
        childUIElement.style.height = null;
      }, { once: true });
    }
  }

  openSubmenu(e, sectionType, sectionTypeOpened) {
    e.preventDefault();
    const { state } = this;

    this.expandCollapsSubMenu(e, state[sectionType]);

    if (
      sectionType === 'enterpriseSolutionsTab'
      || sectionType === 'consumerProductsTab'
      || sectionType === 'pricingTab'
      || sectionType === 'consumerAudiencesTab'
      || sectionType === 'enterpriseAudiencesTab'
      || sectionType === 'keyFeaturesTab'
      || sectionType === 'contentTab'
    ) {
      this.setState({
        allSectionOpened: false,
        pricingSectionOpened: false,
        personalisedSectionOpened: false,
        preferencesSectionOpened: false,
        moreSectionOpened: false,
        consumerProductsTab: false,
        enterpriseSolutionsTab: false,
        pricingTab: false,
        consumerAudiencesTab: false,
        enterpriseAudiencesTab: false,
        keyFeaturesTab: false,
        contentTab: false,
        leftBarOpened: true,
        [sectionType]: !sectionTypeOpened,
      });
    } else {
      this.setState({
        allSectionOpened: false,
        pricingSectionOpened: false,
        personalisedSectionOpened: false,
        preferencesSectionOpened: false,
        moreSectionOpened: false,
        productsSectionOpened: false,
        consumerProductsTab: false,
        enterpriseSolutionsTab: false,
        pricingTab: false,
        consumerAudiencesTab: false,
        enterpriseAudiencesTab: false,
        keyFeaturesTab: false,
        contentTab: false,
        [sectionType]: !sectionTypeOpened,
      });
    }
    const { closeNavMenu } = this.props;
    closeNavMenu();
  }

  checkActivity(e, isActive, menuItem) {
    const { piwikEnabled, token, actions } = this.props;

    if (!token && menuItem.menuCategory === 'preferences' && menuItem.name !== 'Manage Watchlists') {
      actions.setAuthModalOpened(true);
      e.preventDefault();
    }

    if (isActive) e.preventDefault();

    if (!token && menuItem.name === 'Bookmarks') {
      actions.setAuthModalOpened(true);
      e.preventDefault();
    }

    if (piwikEnabled && menuItem.name) {
      _paq.push(['trackEvent', 'Left bar', `${menuItem.name} (LB)`]);
    }

    e.stopPropagation();

    this.setState({
      leftBarOpened: false,
      consumerProductsTab: false,
      enterpriseSolutionsTab: false,
      pricingTab: false,
      consumerAudiencesTab: false,
      enterpriseAudiencesTab: false,
      keyFeaturesTab: false,
      contentTab: false,
    });
  }

  checkSocialActivity(e, isActive, menuItem) {
    const { piwikEnabled } = this.props;

    if (piwikEnabled) {
      switch (menuItem.class) {
        case 'facebook':
          _paq.push(['trackEvent', 'Left bar', 'Facebook (LB)']);
          break;
        case 'twitter':
          _paq.push(['trackEvent', 'Left bar', 'Twitter (LB)']);
          break;
        default:
      }
    }
  }

  openedActivePathTab(menuCategory) {
    const { initialCategoryExpanded, currentMenuCategory } = this.state;

    // check if menu was expanded on initial load
    if (initialCategoryExpanded && currentMenuCategory === menuCategory) return;

    this.setAllTabsToDefault();

    switch (menuCategory) {
      case 'preferences':
        this.setState({
          preferencesSectionOpened: true,
          initialCategoryExpanded: true,
          currentMenuCategory: menuCategory,
        });
        break;
      case 'more':
        this.setState({
          moreSectionOpened: true,
          initialCategoryExpanded: true,
          currentMenuCategory: menuCategory,
        });
        break;
      case 'consumerProducts':
        this.setState({
          consumerProductsTab: true,
          productsSectionOpened: true,
          initialCategoryExpanded: true,
          currentMenuCategory: menuCategory,
        });
        break;
      case 'enterpriseProducts':
        this.setState({
          enterpriseSolutionsTab: true,
          productsSectionOpened: true,
          initialCategoryExpanded: true,
        });
        break;
      case 'consumerAudiences':
        this.setState({
          consumerAudiencesTab: true,
          productsSectionOpened: true,
          initialCategoryExpanded: true,
          currentMenuCategory: menuCategory,
        });
        break;
      case 'enterpriseAudiences':
        this.setState({
          enterpriseAudiencesTab: true,
          productsSectionOpened: true,
          initialCategoryExpanded: true,
          currentMenuCategory: menuCategory,
        });
        break;
      case 'keyFeatures':
        this.setState({
          keyFeaturesTab: true,
          productsSectionOpened: true,
          initialCategoryExpanded: true,
          currentMenuCategory: menuCategory,
        });
        break;
      case 'content':
        this.setState({
          contentTab: true,
          productsSectionOpened: true,
          initialCategoryExpanded: true,
          currentMenuCategory: menuCategory,
        });
        break;
      case 'pricing':
        this.setState({
          pricingSectionOpened: true,
          initialCategoryExpanded: true,
          currentMenuCategory: menuCategory,
        });
        break;
      case 'personalised':
        this.setState({
          personalisedSectionOpened: true,
          initialCategoryExpanded: true,
          currentMenuCategory: menuCategory,
        });
        break;
      case 'all':
        this.setState({
          allSectionOpened: true,
          initialCategoryExpanded: true,
          currentMenuCategory: menuCategory,
        });
        break;
      default:
    }
  }

  toggleHomepageLeftbar() {
    const { closeNavMenu } = this.props;
    this.setState(({ homepageLeftbarOpened }) => ({
      homepageLeftbarOpened: !homepageLeftbarOpened,
      ...(homepageLeftbarOpened === false && {
        leftBarOpened: homepageLeftbarOpened,
      }),
    }), () => {
      this.toggleForHomePage();
    });

    closeNavMenu();
  }

  toggleForHomePage(isLeftBarCheck) {
    const { href } = this.props;
    if (href !== '/') return;

    const { homepageLeftbarOpened, leftBarOpened } = this.state;

    this.setState({
      leftBarOpened: isLeftBarCheck ? leftBarOpened : homepageLeftbarOpened,
    });
  }

  setAllTabsToDefault() {
    this.setState({
      preferencesSectionOpened: false,
      moreSectionOpened: false,
      productsSectionOpened: false,
      consumerProductsTab: false,
      enterpriseSolutionsTab: false,
      consumerAudiencesTab: false,
      enterpriseAudiencesTab: false,
      keyFeaturesTab: false,
      contentTab: false,
      pricingSectionOpened: false,
      personalisedSectionOpened: false,
    });
  }

  render() {
    const {
      href,
      client,
      lightLeftBar,
      token,
      promobarHeight,
      promobarType,
      accessLevels,
      isRailsContext,
      isDetailsPage,
      width,
      actions,
      onboarding,
      user,
    } = this.props;
    const {
      leftBarOpened, allSectionOpened, pricingSectionOpened,
      mode, styles, isHeightSmall,
      preferencesSectionOpened, moreSectionOpened, productsSectionOpened,
      homepageLeftbarOpened, isMobileFixed, personalisedSectionOpened,
    } = this.state;

    const { state } = this;

    const leftBar = cx('left-bar', {
      opened: leftBarOpened,
      onboarding,
      'is-mobile': isMobileOnly,
      'is-small': isHeightSmall,
      'light-leftbar': lightLeftBar,
      'light-leftbar-visible': homepageLeftbarOpened && width >= 1024, // only for desktop
    });

    const innerWrapper = cx('wrapper', { 'promobar-present': promobarHeight });

    const leftbarToggleMethod = lightLeftBar && width >= 1025
      ? this.toggleHomepageLeftbar
      : this.toggleLeftBar;

    return (
      <>
        <ToggleBtn
          opened={leftBarOpened}
          homepageLeftbarOpened={homepageLeftbarOpened}
          onClick={leftbarToggleMethod}
          mode={mode}
          isMobile={isMobile}
          lightLeftBar={lightLeftBar || isDetailsPage}
          isHomePage={lightLeftBar}
          promobarHeight={promobarHeight}
          promobarType={promobarType}
          checkForMobile={isMobileFixed}
          toggleBtnRef={this.toggleBtnRef}
          isOnboarding={onboarding}
        />
        <aside className={leftBar} style={styles} ref={this.leftBarRef}>
          <article className={innerWrapper}>
            {mode === DESKTOP && (
              <button
                type="button"
                className={cx('expand-button')}
                onClick={this.toggleLeftBar}
              >
                <div className={Styles.icon}>
                  <FontAwesomeIcon icon={faChevronRightLight} />
                </div>
                <div className={Styles.hide}>
                  Minimize
                </div>
              </button>
            )}
            <section className={cx('items-container')}>
              <Item
                expanded={allSectionOpened}
                leftBarOpened={leftBarOpened}
                item={{
                  name: 'CityFALCON  Chat',
                  icon: <img src={cityFalconIcon} alt="cityFalcon" />,
                  href: '/chatbot',
                  new: true,
                }}
                homepageLeftbarItem={lightLeftBar}
                isRailsContext={isRailsContext}
                listItemType="headerItem"
                matchFixURL
                activePath={href}
                checkActivity={this.checkActivity}
              />
            </section>
            <section className={cx('items-container', { opened: personalisedSectionOpened })}>
              <Item
                expanded={personalisedSectionOpened}
                leftBarOpened={leftBarOpened}
                item={{
                  name: 'Personalised Content',
                  icon: <FontAwesomeIcon icon={faUserChartLight} />,
                  href: '#',
                }}
                onClick={(e) => this.openSubmenu(e, 'personalisedSectionOpened', personalisedSectionOpened)}
                homepageLeftbarItem={lightLeftBar}
                isRailsContext={isRailsContext}
                listItemType="headerItem"
              >
                <FontAwesomeIcon icon={faChevronDownLight} />
              </Item>
              <ul>
                {personalisedContentTabs.map((tab) => (
                  <li key={tab.id}>
                    <Item
                      token={token}
                      actions={actions}
                      item={tab}
                      activePath={href}
                      leftBarOpened={leftBarOpened}
                      expanded={personalisedSectionOpened}
                      checkActivity={this.checkActivity}
                      path={this.path}
                      listItemType="menuListItem"
                      homepageLeftbarItem={lightLeftBar}
                      openedActivePathTab={this.openedActivePathTab}
                      isRailsContext={isRailsContext}
                      matchFixURL={tab.matchFixURL}
                    />
                  </li>
                ))}
              </ul>
            </section>
            <section className={cx('items-container', { opened: allSectionOpened })}>
              <Item
                expanded={allSectionOpened}
                leftBarOpened={leftBarOpened}
                item={{
                  name: 'All Content',
                  icon: <FontAwesomeIcon icon={faFileAltLight} />,
                  href: '#',
                }}
                onClick={(e) => this.openSubmenu(e, 'allSectionOpened', allSectionOpened)}
                homepageLeftbarItem={lightLeftBar}
                isRailsContext={isRailsContext}
                listItemType="headerItem"
              >
                <FontAwesomeIcon icon={faChevronDownLight} />
              </Item>
              <ul>
                {allContentTabs.map((tab) => (
                  <li key={tab.id}>
                    <Item
                      item={tab}
                      activePath={href}
                      leftBarOpened={leftBarOpened}
                      expanded={allSectionOpened}
                      checkActivity={this.checkActivity}
                      path={this.path}
                      listItemType="menuListItem"
                      homepageLeftbarItem={lightLeftBar}
                      openedActivePathTab={this.openedActivePathTab}
                      isRailsContext={isRailsContext}
                      matchFixURL={tab.matchFixUrl}
                    />
                  </li>
                ))}
              </ul>
            </section>
            <section className={cx('items-container')}>
              <Item
                expanded={allSectionOpened}
                leftBarOpened={leftBarOpened}
                item={{
                  name: 'Search',
                  icon: <FontAwesomeIcon icon={faSearchLight} />,
                  href: '/search',
                }}
                homepageLeftbarItem={lightLeftBar}
                isRailsContext={isRailsContext}
                listItemType="headerItem"
                matchFixURL
                activePath={href}
                checkActivity={this.checkActivity}
              />
            </section>
            <section className={cx('items-container', { opened: preferencesSectionOpened })}>
              <Item
                expanded={preferencesSectionOpened}
                leftBarOpened={leftBarOpened}
                item={{
                  name: 'Preferences',
                  icon: <FontAwesomeIcon icon={faCogLight} />,
                  href: '#',
                }}
                onClick={(e) => this.openSubmenu(e, 'preferencesSectionOpened', preferencesSectionOpened)}
                homepageLeftbarItem={lightLeftBar}
                isRailsContext={isRailsContext}
                listItemType="headerItem"
              >
                <FontAwesomeIcon icon={faChevronDownLight} />
              </Item>
              <ul>
                {preferencesTabs.map((tab) => (
                  <li key={tab.id}>
                    <Item
                      item={tab}
                      activePath={href}
                      leftBarOpened={leftBarOpened}
                      expanded={preferencesSectionOpened}
                      checkActivity={this.checkActivity}
                      path={this.path}
                      listItemType="menuListItem"
                      homepageLeftbarItem={lightLeftBar}
                      token={token}
                      openedActivePathTab={this.openedActivePathTab}
                      isRailsContext={isRailsContext}
                    />
                  </li>
                ))}
              </ul>
            </section>

            <section className={cx('items-container', { opened: productsSectionOpened })}>
              <Item
                expanded={productsSectionOpened}
                leftBarOpened={leftBarOpened}
                item={{
                  name: 'Product Info',
                  icon: <FontAwesomeIcon icon={faBoxCheckLight} />,
                  href: '#',
                }}
                onClick={(e) => this.openSubmenu(e, 'productsSectionOpened', productsSectionOpened)}
                homepageLeftbarItem={lightLeftBar}
                isRailsContext={isRailsContext}
                listItemType="headerItem"
              >
                <FontAwesomeIcon icon={faChevronDownLight} />
              </Item>
              <ul className={Styles['submenu-list']}>
                {productsTabs.map((tab) => (
                  <li key={tab.id}>
                    {
                      tab.name === 'All Features and Content' ? (
                        <Item
                          expanded={state[tab.tabType]}
                          parentMenuExpanded={productsSectionOpened}
                          leftBarOpened={leftBarOpened}
                          listItemType="subMenuListItem"
                          homepageLeftbarItem={lightLeftBar}
                          item={{
                            name: tab.name,
                            icon: tab.icon,
                            href: tab.href,
                            isReload: true,
                          }}
                          activePath={tab.href}
                          isRailsContext={isRailsContext}
                        />
                      ) : (
                        <Item
                          expanded={state[tab.tabType]}
                          parentMenuExpanded={productsSectionOpened}
                          leftBarOpened={leftBarOpened}
                          listItemType="subMenuListItem"
                          homepageLeftbarItem={lightLeftBar}
                          item={{
                            name: tab.name,
                            icon: tab.icon,
                            href: '#',
                          }}
                          onClick={(e) => this.openSubmenu(e, tab.tabType, state[tab.tabType])}
                          isRailsContext={isRailsContext}
                        >
                          <FontAwesomeIcon icon={faChevronDownLight} />
                        </Item>
                      )
                    }
                    <ul className={cx('sub-list', { 'opened-sublist': state[tab.tabType] })}>
                      <li>
                        {tab.subTabs.map((subTab) => (
                          <Item
                            isSubMenu
                            matchFixURL
                            item={subTab}
                            expanded={state[tab.tabType]}
                            subMenuOpened={state[tab.tabType]}
                            activePath={href}
                            checkActivity={this.checkActivity}
                            path={this.path}
                            listItemType="menuListItem"
                            key={subTab.id}
                            homepageLeftbarItem={lightLeftBar}
                            openedActivePathTab={this.openedActivePathTab}
                            isRailsContext={isRailsContext}
                          />
                        ))}
                      </li>
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
            <section className={cx('items-container', { opened: pricingSectionOpened })}>
              <Item
                expanded={pricingSectionOpened}
                leftBarOpened={leftBarOpened}
                item={{
                  name: 'Product Pricing',
                  icon: <FontAwesomeIcon icon={faDonateLight} />,
                  href: '#',
                }}
                onClick={(e) => this.openSubmenu(e, 'pricingSectionOpened', pricingSectionOpened)}
                homepageLeftbarItem={lightLeftBar}
                isRailsContext={isRailsContext}
                listItemType="headerItem"
              >
                <FontAwesomeIcon icon={faChevronDownLight} />
              </Item>
              <ul>
                {pricingTabs.map((tab) => (
                  <li key={tab.id}>
                    <Item
                      item={tab}
                      activePath={href}
                      leftBarOpened={leftBarOpened}
                      expanded={pricingSectionOpened}
                      checkActivity={this.checkActivity}
                      path={this.path}
                      listItemType="menuListItem"
                      homepageLeftbarItem={lightLeftBar}
                      openedActivePathTab={this.openedActivePathTab}
                      isRailsContext={isRailsContext}
                      matchFixURL={tab.matchFixUrl}
                    />
                  </li>
                ))}
              </ul>
            </section>

            <section className={cx('items-container', { opened: moreSectionOpened })}>
              <Item
                expanded={moreSectionOpened}
                leftBarOpened={leftBarOpened}
                item={{
                  name: 'More',
                  icon: <FontAwesomeIcon icon={faWindowRestoreLight} />,
                  href: '#',
                }}
                onClick={(e) => this.openSubmenu(e, 'moreSectionOpened', moreSectionOpened)}
                homepageLeftbarItem={lightLeftBar}
                isRailsContext={isRailsContext}
                listItemType="headerItem"
              >
                <FontAwesomeIcon icon={faChevronDownLight} />
              </Item>
              <ul>
                {moreTabs.map((tab) => (
                  <li key={tab.id}>
                    <Item
                      item={tab}
                      activePath={href}
                      expanded={moreSectionOpened}
                      leftBarOpened={leftBarOpened}
                      checkActivity={this.checkActivity}
                      path={this.path}
                      listItemType="menuListItem"
                      homepageLeftbarItem={lightLeftBar}
                      openedActivePathTab={this.openedActivePathTab}
                      isRailsContext={isRailsContext}
                    />
                  </li>
                ))}
              </ul>
            </section>
            <section>
              {(!token || !accessLevels.includes(SILVER)) && (
                <div className={cx('upgrade_button')}>
                  {isRailsContext ? (
                    <a
                      href="/pricing"
                    >
                      Start 7-day Free Trial
                      <span>No credit card required</span>
                    </a>
                  ) : (
                    <Link
                      to="/pricing"
                    >
                      Start 7-day Free Trial
                      <span>No credit card required</span>
                    </Link>
                  )}
                </div>
              )}
              {token && accessLevels.includes(SILVER) && !accessLevels.includes(GOLD) && (
                <div className={cx('upgrade_button', 'gold')}>
                  {isRailsContext ? (
                    <a
                      href="/pricing"
                    >
                      Upgrade to Gold
                    </a>
                  ) : (
                    <Link
                      to="/pricing"
                    >
                      Upgrade to Gold
                    </Link>
                  )}
                </div>
              )}
            </section>
            <article className={Styles.bottom}>
              {socialMediaTabs.map((tab) => (
                <section key={tab.id} className={cx('items-container', tab.class)}>
                  <Item
                    item={tab}
                    activePath={href}
                    homepageLeftbarItem={lightLeftBar}
                    expanded={leftBarOpened}
                    leftBarOpened={leftBarOpened}
                    path={this.path}
                    target="_blank"
                    social
                    isMobile={isMobileOnly}
                    isSmall={isHeightSmall}
                    checkActivity={this.checkSocialActivity}
                    isRailsContext={isRailsContext}
                    listItemType="headerItem"
                  />
                </section>
              ))}
            </article>
          </article>
        </aside>
      </>
    );
  }
}

LeftBar.propTypes = {
  href: PropTypes.string,
  client: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  closeNavMenu: PropTypes.func,
};

LeftBar.defaultProps = {
  href: '',
  client: null,
  closeNavMenu: () => { },
};

const mapToStateProps = (
  {
    promobar,
    watchlistReducer,
    subscriptions,
    common,
    userPreferencesReducer,
  },
  { href },
) => ({
  promobarHeight: promobar.height,
  href,
  token: watchlistReducer.userToken,
  promobarType: promobar.promobarType,
  accessLevels: subscriptions.permissions.access_levels,
  width: common.width,
  user: userPreferencesReducer.user,
  isCuratorPlan: subscriptions.permissions.user.premium_plan_keys.includes('curator'),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    ...authentication,
  }, dispatch),
});

export default withComponentName(connect(mapToStateProps, mapDispatchToProps)(LeftBar));
